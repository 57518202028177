import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import GetInTouch from "../components/getInTouch"

const ServicesPage = props => {
  return (
    <Layout location={props.location}>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="At C.D. Carter & Co we offer all aspects of roofing services from roof repairs to complete re-roofing projects. Specialists in periodic Grade I & II listed buildings, Churches, Lutyens houses and buildings of interest. Find out how we can help you."
        />
      </Helmet>

      <section className="hero">
        <div className="container">
          <PageTitle
            title="Our Services"
            subtitle="From roof repairs to complete re-roofing projects, plus everything in-between"
          />
        </div>
      </section>

      <section className="service" id="residential">
        <div className="container">
          <div className="service-gallery">
            <div className="gallery-image">
              <StaticImage
                src="../images/services/roofing-re-roofing-01.jpg"
                alt="Re-roofing project Godalming Surrey"
                placeholder="blurred"
                layout="constrained"
                width={1080}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/roofing-re-roofing-02.jpg"
                alt="Completed roof refurbishment Caterham Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/roofing-re-roofing-03.jpg"
                alt="Residential re-roof Farnham Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
          </div>

          <div className="service-copy">
            <div className="copy-large">
              <h2 className="service-title">
                Residential Roofing &amp; Re-Roofing
              </h2>
              <p>
                At C.D. Carter & Co we work across all varieties of residential
                roofing and re-roofing, (including flat or pitched roofs,
                slated, hand-tiled, plain tiled and shingled). We promise
                minimal disruption to your home whatever the weather, keeping
                you covered from the elements at all times.
              </p>
              <p>
                Worried about existing weather damage? We offer comprehensive
                repair services and free estimates, so why not{" "}
                <Link to="/contact" className="copy-link">
                  get in touch
                </Link>
                ?
              </p>
            </div>
            <div className="copy-small">
              <p className="small">
                We understand how valuable your home is to you; that is why we
                are dedicated to producing nothing but the very best in quality
                and affordability.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="service" id="listed">
        <div className="container">
          <div className="service-gallery">
            <div className="gallery-image">
              <StaticImage
                src="../images/services/listed-buildings-01.jpg"
                alt="Completed re-roof project Hascombe Surrey"
                placeholder="blurred"
                layout="constrained"
                width={1080}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/listed-buildings-02.jpg"
                alt="Re-roof in progress Sullingstead House Godalming"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/listed-buildings-03.jpg"
                alt="Re-roof Sullingstead House Godalming Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
          </div>

          <div className="service-copy">
            <div className="copy">
              <h2 className="service-title">Grade I & II Listed Buildings</h2>
              <p>
                Specialists in re-roofing and restoration of periodic and Grade
                I & II listed buildings, we use traditional materials and
                workmanship when conducting works to buildings of historic
                value. Over the years we've accumulated extensive experience and
                a lengthy list of clients in this specialist area, including
                having worked on numerous{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Edwin_Lutyens"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="copy-link"
                >
                  Edwin Lutyens
                </a>{" "}
                traditional architectural builds.
              </p>
              <p>
                Our quality of service and specialism are a few of the reasons
                why C.D. Carter & Co are one of the most highly considered and
                endorsed roofing contractors across Surrey and the South of
                England.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="service" id="newbuild">
        <div className="container">
          <div className="service-gallery">
            <div className="gallery-image">
              <StaticImage
                src="../images/services/new-builds-01.jpg"
                alt="New build project Woking Surrey"
                placeholder="blurred"
                layout="constrained"
                width={1080}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/new-builds-02.jpg"
                alt="New roof reclaimed tiles Hascombe Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/new-builds-03.jpg"
                alt="New roof project Farnham Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
          </div>

          <div className="service-copy alt">
            <div className="copy-large">
              <h2 className="service-title">New Builds & Developments</h2>
              <p>
                C.D. Carter & Co provide roofing solutions for all new builds
                and extensions, no matter how big or small the job. We work hard
                to ensure that all our projects are completed to the highest
                possible standard.
              </p>
              <p>
                We pride ourselves on satisfying all our customer’s needs, our
                trained personnel will provide all the help necessary to
                guarantee all jobs run smoothly.
              </p>
            </div>
            <div className="copy-small">
              <p className="small">
                Building strong long-term relationships with our clients since
                2004. All costs are discussed up front, no hidden fees and free
                estimates.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="service" id="churches">
        <div className="container">
          <div className="service-gallery">
            <div className="gallery-image">
              <StaticImage
                src="../images/services/churches-01.jpg"
                alt="Church roof work detail Hascombe Surrey"
                placeholder="blurred"
                layout="constrained"
                width={1080}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/churches-02.jpg"
                alt="Completed Church roof works Hascombe Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
            <div className="gallery-image">
              <StaticImage
                src="../images/services/churches-03.jpg"
                alt="Church roof works in progress Hascombe Surrey"
                placeholder="blurred"
                layout="constrained"
                width={608}
              />
            </div>
          </div>

          <div className="service-copy">
            <div className="copy">
              <h2 className="service-title">Church Roof Works</h2>
              <p>
                C.D. Carter & Co are approved Diocese specialists for all Church
                roof works. We are convinced our expertise, prices and
                workmanship in this area will be unmatched.
              </p>
              <p>
                For more information, or for a complete list of our services
                please feel free to{" "}
                <Link to="/contact" className="copy-link">
                  get in touch
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </section>

      <GetInTouch
        title="Want to work with us?"
        text="Whether you're starting a new project, looking for repairs or interested in a free estimate we can’t wait to hear about it and find the best way for us to help you with it."
      />
    </Layout>
  )
}

export default ServicesPage
